import React, { Component } from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
  },
  query: {
    fetchPolicy: "no-cache",
  },
};

export const GlobalContext = React.createContext();

const cookies = new Cookies();
const urlParams = new URLSearchParams(window.location.search);
export class GlobalProvider extends Component {


    createArray = (obj) => {
      let _regEx = /[^A-Za-z]/gm;
      let rawArray = obj.url.split(_regEx)
        .concat(obj.eml.toLowerCase().split(_regEx))
        .concat(obj.srv.toLowerCase().split(_regEx))
        .concat(obj.acc.toLowerCase().split(_regEx))
        .concat(obj.serviceName.toLowerCase().split(_regEx));
  
      let arrayOfWord = rawArray.filter((val) => {
        return val.length > 3;
      });
      return arrayOfWord;
    }

    check = (string, array) => {
      for (let i = 0; i <= string.length; i++) {
        let checked = false;
        let msg = undefined;
        let chunk = string.substring(i, i + 5);
        if (chunk.length >= 5 ) {
          for (let elem in array) {
          if (array[elem].includes(chunk)) {
          checked = true
            for (let j = i + 5; j <= string.length; j++) {
              chunk = string.substring(i, j)
              if (array[elem].includes(chunk)) {
                msg = {chunk: chunk, elem: array[elem]}
              } else {
                break;
              }
            }
          };
          }
        }
        if (checked) return {checked: checked, msg: msg};
      }
      return {checked: false, msg: ""}
    }

    isEmail = (mail) => {
      let _regEx = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i
      return _regEx.test(mail)
    }

    setUserData = (userData) => {
      this.setState({userData})
    }

    setToken = (token) => {
      this.setState({token})
    }

    checkExp = () => {
      let _now = Math.floor(new Date().getTime()/1000.0)
      if (cookies.get("token") && jwt_decode(cookies.get("token")).exp < _now ) {
        return true
      } else {
        return false
      }
    }

    isInvalid = () => {
      const _now = Math.floor(new Date().getTime()/1000.0)

      if ( jwt_decode(cookies.get("token")).exp < _now || urlParams.get("change") || urlParams.get("code") ) {
        return true
      } else {
        return false
      }

    }

    state = {
      createArray: this.createArray,
      check: this.check,
      isEmail: this.isEmail,
      setUserData: this.setUserData,
      setToken: this.setToken,
      checkExp: this.checkExp,
      isInvalid: this.isInvalid,
      userData: (cookies.get("token") && jwt_decode(cookies.get("token"))) || {},
      token: cookies.get("token") || null,
      client: new ApolloClient({
        uri: "https://bso.inrete.it/graphql",
        cache: new InMemoryCache(),
        defaultOptions: defaultOptions,
      }),
      appReady: false
    };

    componentDidMount() {

      if (cookies.get("token") && this.isInvalid()) {
        cookies.remove("token", { path: "/" })
        this.setState({appReady: true})
      } else {
        this.setState({appReady: true})
      }

    }

    render() {
        return (
            this.state.appReady ?
            <GlobalContext.Provider value={this.state}>
                {this.props.children}
            </GlobalContext.Provider>
            : null
        )
    }
}